/*
.backdrop{
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal{
  width: clamp(50%, 700px, 90%);
  height: auto;
  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orange-gradient {
  background: white;
}
*/
.btn:hover{
  background: var(--primary-3rd-color) !important;
}

h5{
  margin: 15px 0;
  //text-transform: lowercase;
}
.modal-body{
  padding: 10px 50px 20px !important;
  border-radius: 8px;
  max-width: 850px;
  margin: 0;
}
.modal-footer{
  border-top: none !important;
}

.padding-right{
  padding-right: 10px;
}

.padding-left{
  padding-left: 10px;
}

.form-control{
  background: #F0F5F7;
  border: 1px solid #F0F5F7;
  padding: 15px 20px;
  border-radius: 8px;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: #8AB2F9;
  transform-origin: 0;
  z-index: 999;
}

.card-skeleton{
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: 1px solid grey;
  border-radius: 5px;
}

.card-skeleton .left-col{
  margin-right: 1rem;
}

.card-skeleton .right-col{
  flex: 1;
}

.main-header-mobile .inner-box{
  //width: 65% !important;
}

.pro-header{
  img{
    max-width: 60% !important;
  }
}

.icon-options-formation{
  background: #dee8ff;
  border-radius: 50%;
  padding: 6px;
}
.list-style-three{
  margin-top: 15px;
}
.sidebar .list-style-three {
  margin-bottom: 10px;
}
.candidate-block-three{
  margin-bottom: 15px;
}

.candidate-block-three .inner-box .content {
  padding-left: 50px !important;
}
.candidate-block-three .inner-box .content .block3 {
  margin-left: -35px;
}

.candidate-block-three .inner-box {
  //padding: 10px 30px 10px 15px;
}

.candidate-block-three .image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--primary-color);
  z-index: 1;
  padding: 8px;
}

.list-style-three li{
  margin-bottom: 0;
}

.candidate-block-three .name {
  margin-top: 6px;
  width: fit-content;
}

.libre-padding {
  padding-right: 50px;

  @media only screen and (max-width: 990px) {
    padding-right: 0;
  }
}

.libre-title{
  color: rgba(24, 54, 178, 1);
  font-size: 40px;
  font-family: Barlow, sans-serif;
  font-weight: 800;
  text-align: center;
  line-height: 44px;
}
.libre-text a {
  margin: 0 !important;
}
.libre-text > div {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
}

.libre img{
  max-width: 100%;
  object-fit: cover;
  border-radius: 30px;
  overflow: hidden;
}
.sidebar img.border-arrondi{
  border-radius: 30px;
}
.jeu .sidebar1 img.border-arrondi{
  border-radius: 0px;
}
.sidebar img.sans-arrondi{
  border-radius: 0px;
}
.page-left {
  h3 {
    margin: 0 0 20px 0 !important;
  }
}

.session-card h4{
  max-width: 75%;
}

.session-card-description{
  max-width: 70%;
}

.session-card-bottom{
  display: flex;
  gap: 20px;
  //justify-content: space-between;
  margin-top: 3px;
}

.custom-placeholder{
  color: #5d5d5d !important;
  //font-style: italic;
  font-size: 14px !important;
}

.libre h3,
.shadow-title {
  background: var(--primary-3rd-color);
  color: var(--primary-color);
  font-family: Barlow, sans-serif;
  font-size: 22px;
  line-height: 25px;
  font-weight: 900;
  padding: 10px 20px;
  -webkit-box-shadow: 7px 8px 15px -10px #B5B5B5;
  box-shadow: 7px 8px 15px -10px #B5B5B5;
  width: fit-content;
  //margin: 0 0 30px 0;
  margin: 0 auto 20px;

  &.blue {
    color: var(--primary-color);
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }

  &.left {
    //margin: 0 auto;
    margin: 0 0 30px 0;
  }
}
.plan-formation-details-title {
  font-weight: bold;
  margin: 20px 0;
}
.plan-formation-details > ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
}
.plan-formation-details > ul > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.plan-formation-details > ul > li:before{
  display: flex;
  position: relative;
  content: "";
  background: url("../images/icons/bullet.png") no-repeat center center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.libre h3{
  font-size: 22px;
}
.calendar-grid{
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 520px;
  margin: auto;
  align-items: center;
  justify-content: space-between;

  .date{
    display: flex;
    gap: 1rem ;
    min-width: 280px;
  }
}

/* LoadingSpinner.css */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.main-header.alternate {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Transitions.css */
.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: translateX(0);
}

.page-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

.statut{
  border-radius: 50px;
  font-size: 13px;
  left: 10px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  position: absolute;
  top: 10px;
  z-index: 1;
}

.statut.ouvert{
  background: #C3FFB5;
  color: black;
}

.statut.ferme{
  background: #E5EBF5;
  color: #202124;
}
.libre-sidebar ul li,
.libre ul li{
  margin-bottom: 0;
  //margin-left: 15px;
  //list-style: disc;
}
.footer-qualiopi{
  //display: flex;
  font-size: 13px;

}
.footer-qualiopi span{
  color: var(--primary-color);
  font-weight: bold;
}
.footer-qualiopi div{
  margin-top: 25px;
  line-height: 27px;
}

.slick-slide img {
  margin: auto;
}

//image effect

.scroll-effect {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.scroll-effect.in-view {
  opacity: 1;
  transform: scale(1);
}

//

.block-code{
  background: #f3f3f3;
  width: 60%;
  margin: 10px auto;
  font-weight: bold;
  padding: 5px;
}

//Notation

.notation{
  display: flex;
  gap: 20px;
  margin: 10px 0;
  flex-direction: column;

  span{
    color: #000000;
    font-stretch: condensed;
  }

  @media only screen and (max-width: 1266px) {
    margin: 30px 0;
  }
}

.rating-wrapper {
  align-items: center;
  display: flex;
  height: 30px;
  position: relative;
  align-content: center;
  justify-content: center;
}

.rating-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.rating {
  display: flex;
  justify-content: end;
  padding-right: 10px;
  position: relative;
  width: 200px;
  height: 30px;
  z-index: 1;
  align-items: center;
}

.rating-title{
  font-family: Barlow, sans-serif;
  font-weight: 900;
  font-size: 14px;
  margin: 8px 0;
}

.rating-asterisk {
  display: flex;
  justify-content: right;
  width: 100%;
  font-size: 4px;
}

.rating-asterisk-text {
  width: 200px;
  font-size: 10px;
  text-align: center;
  line-height: 1;
}

.circle {
  position: absolute;
  background-color: #b0f7af;
  color: #333;
  font-family: Barlow, sans-serif;
  font-weight: 900;
  font-size: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  left: 10px;

}

.stars {
  display: flex;
  width: 120px;
  justify-content: space-evenly;
}

.stars span {
  color: #b0f7af !important;
  font-size: 20px;
}

.rating-wrapper::before {
  position: absolute;
  background-color: #333;
  border-radius: 8px;
  content: "";
  display: ruby;
  height: 100%;
  width: 100%;
  transform: skew(20deg);
  z-index: 0;
  margin: 7px;
}


// chapitre

span.chapitre a{
  text-decoration: none;
}

//modal

#mainContainer{
  min-width: auto !important;
}

//h1 page formation

h1.petit{
  font-size: 35px !important;
  line-height: 35px !important;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity .3s;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.main-image img {
  object-position: right top;

  @media  only screen and (max-device-width: 480px) {
    object-position: 90% top;
  }
}

.no-margin {
  margin: 0 !important;
}