.slick-dots li {
    margin: 0 5px;
    width: 8px;
    height: 8px;
    background: #bfc8cb;
    border-radius: 10px;
    transition: all 300ms ease;
    button {
        padding: 0;

        &::before {
            display: none;
        }
    }
    &.slick-active {
        width: 20px;
        background: #202124;
    }
}

.slick-dots {
    position: static;
    margin-top: 40px;
}

.gap-x25 {
    .slick-slide {
        padding: 0 15px;
    }
    .slick-list {
        margin: 0 -15px;
    }
}

// slick navigation
.slick-arrow {
    position: absolute;
    z-index: 2;
    background: #e2eaf8;
    border-radius: 8px;
    color: var(--primary-color);
    height: 50px;
    width: 50px;
    opacity: 1;
    line-height: 50px;
    text-align: center;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    transition: all 300ms ease;

    &:hover,
    &:focus {
        background: var(--primary-color);
        color: #fff;
    }

    &::before {
        display: none;
    }
}

.arrow-center-between {
    .slick-prev {
        left: 25px;
    }
    .slick-next {
        right: 25px;
    }
}

.arrow-rounded {
    .slick-arrow {
        border-radius: 50%;
    }
}
