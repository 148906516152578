@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?l8rg88');
  src:  url('../fonts/icomoon.eot?l8rg88#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?l8rg88') format('truetype'),
    url('../fonts/icomoon.woff?l8rg88') format('woff'),
    url('../fonts/icomoon.svg?l8rg88#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-drawing:before {
  content: "\e903";
}
.icon-process:before {
  content: "\e904";
}
.icon-task:before {
  content: "\e905";
}
.icon-one-finger-click:before {
  content: "\e906";
}
.icon-case:before {
  content: "\e900";
}
.icon-contact:before {
  content: "\e901";
}
.icon-doc:before {
  content: "\e902";
}
