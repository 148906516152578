/*** 

====================================================================
Top Companies
====================================================================

***/

.top-companies{
  position: relative;
  padding: 100px 0;
}

.top-companies .carousel-outer{
  margin: 0 -15px;
}

.top-companies .companies-carousel .owl-dots{
  margin-top: 20px;
}

/* Company Block */

.company-block{
  position: relative;
  padding: 0 15px;
  margin-bottom: 30px;
}

.company-block .inner-box{
  position: relative;
  padding: 30px 30px;
  background: #FFFFFF;
  border: 1px solid #ECEDF2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
}

.company-block .inner-box.bg-clr-1{background: #FCF9F4;}
.company-block .inner-box.bg-clr-2{background: #F4F6FA;}
.company-block .inner-box.bg-clr-3{background: #F9F9F9;}
.company-block .inner-box.bg-clr-4{background: #FCF4FC;}
.company-block .inner-box.bg-clr-5{background: #FAFCF3;}

.company-block .inner-box:hover{
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.company-block .image{
  position: relative;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 14px;
}

.company-block .image img{
  width: 100%;
}

.company-block .name{
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #202124;
  margin-bottom: 5px;
}

.company-block .designation{
  display: block;
  font-size: 14px;
  color: var(--primary-color);
  line-height: 19px;
  margin-bottom: 10px;
}

.company-block .location{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #696969;
  margin-bottom: 25px;
}

.company-block .location i{
  font-size: 14px;
  margin-right: 5px;
}

.company-block .theme-btn{
  max-width: 240px;
  width: 100%;
}

/* Company Block Two */

.company-block-two{
  position: relative;
  padding: 0 15px;
  margin-bottom: 30px;
}

.company-block-two .inner-box{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 30px;
  background: #FFFFFF;
  border: 1px solid #ECEDF2;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-two .inner-box:hover{
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.company-block-two .inner-box .content{
  position: relative;
  padding-left: 110px;
  min-height: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.company-block-two .image{
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 14px;
}

.company-block-two .image img{
  width: 100%;
}

.company-block-two .name{
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 5px;
}

.company-block-two .designation{
  display: block;
  font-size: 14px;
  color: var(--primary-color);
  line-height: 19px;
  margin-bottom: 10px;
}

.company-block-two .location{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #696969;
}

.company-block-two .location i{
  font-size: 14px;
  margin-right: 5px;
}

.company-block-two .theme-btn{
  width: 150px;
  font-size: 14px;
  line-height: 20px;
  padding:5px 23px; 
  font-weight: 400;
  border-radius: 20px;
}

/* Company Block Three*/

.company-block-three{
  position: relative;
  margin-bottom: 30px;
}

.company-block-three .inner-box{
  position: relative;
  padding: 32px 20px 22px 30px;
  background: #FFFFFF;
  border: 1px solid #ECEDF2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-three .inner-box:hover{
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.company-block-three .content{
  position: relative;
  padding-left: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  min-height: 51px;
}

.company-block-three .company-logo{
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-three h4{
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.company-block-three h4 a{
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-three h4 a:hover{
  color: var(--primary-color);
}

.company-block-three .job-info{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.company-block-three .job-info li{
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.company-block-three .job-info li .icon{
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.company-block-three .job-info li a{
  color: #696969;
}

.company-block-three .bookmark-btn{
  position: absolute;
  right: 10px;
  top: 15px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: #ECEDF2;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
}

.company-block-three .inner-box:hover .bookmark-btn{
  opacity: 1;
  visibility: visible;
}

.company-block-three .job-other-info{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/*Company Block Four*/

.company-block-four{
  position: relative;
  margin-bottom: 30px;
}

.company-block-four .inner-box{
  position: relative;
  padding: 30px 15px 30px;
  background: #FFFFFF;
  border: 1px solid #ECEDF2;
  border-radius: 8px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four .inner-box:hover{
  -webkit-box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 7px 18px rgba(64, 79, 104, 0.05);
}

.company-block-four .featured{
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  color: #34A853;
  background: rgba(52,168,83,.15);
}

.company-block-four .company-logo{
  position: relative;
  display: block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 12px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four .company-logo img{
  display: block;
  width: 100%;
}

.company-block-four h4{
  font-size: 18px;
  color: #202124;
  font-weight: 500;
  line-height: 26px;
  top: -3px;
  margin-bottom: 3px;
}

.company-block-four h4 a{
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four h4 a:hover{
  color: var(--primary-color);
}

.company-block-four .job-info{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.company-block-four .job-info li{
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.company-block-four .job-info li .icon{
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.company-block-four .job-info li a{
  color: #696969;
}

.company-block-four .job-type{
  position: relative;
  display: inline-block;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  color: var(--primary-color);
  background: rgba(25,103,210,.15);
}

.company-block-four .bookmark-btn{
  position: absolute;
  right: 15px;
  top: 15px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.company-block-four .inner-box:hover .bookmark-btn{
  background: #ECEDF2;
  opacity: 1;
  visibility: visible;
}

/* Style Two*/

.top-companies.style-two{
  background: #F3F7FB;
}

.top-companies.style-two .company-block .image img{
  border-radius: 50%;
}

.top-companies.style-two .company-block .theme-btn{
  border-radius: 50px;
}

/* Style Three*/

.top-companies.style-three .company-block{
  padding: 0 18px;
  margin-bottom: 50px;
}

.top-companies.style-three .company-block .inner-box{
  padding: 32px 20px 20px;
}

.top-companies.style-three .company-block .image{
  height: 80px;
  width: 80px;
  margin-bottom: 25px;
}