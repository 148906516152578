.cluster-visible {
  text-align: center;
  font-size: 16px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  background-color: var(--primary-color);
  border: 7px solid rgba(25, 103, 210, 0.2);
  -webkit-box-shadow: 0 7px 30px rgba(33, 33, 33, 0.3);
  box-shadow: 0 7px 30px rgba(33, 33, 33, 0.3);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-clip: content-box;
}

.map-marker-container {
  position: absolute;
  margin-top: 10px;
  -webkit-transform: translate3d(-50%, -100%, 0);
  transform: translate3d(-50%, -100%, 0);
}

.marker-container {
  position: relative;
  margin: 10px auto;
  top: -5px;
  width: 46px;
  height: 46px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  -webkit-perspective: 1000;
}

.face {
  position: absolute;
  width: 46px;
  height: 46px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  z-index: 100;
  background: #4cbfd8;
  font-size: 24px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-clip: content-box;
  line-height: 46px;
}

.face:before {
  position: absolute;
  left: -7px;
  top: -7px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 7px solid rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 0 7px 30px rgba(33, 33, 33, 0.1);
  box-shadow: 0 7px 30px rgba(33, 33, 33, 0.1);
  content: "";
}

.face > div {
  background-image: url(../../images/resource/company-logo/3-1.png);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.map-marker-container:hover .marker-container .marker-card,
.clicked .marker-container .marker-card,
.map-marker-container.clicked .marker-card {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#singleListingMap .marker-container {
  cursor: default;
}

.map-marker-container[data-marker_id="0"] .marker-arrow:before {
  border-color: #4cbfd8 transparent transparent;
}
.map-marker-container[data-marker_id="1"] .marker-arrow:before {
  border-color: #ff8480 transparent transparent;
}
.map-marker-container[data-marker_id="2"] .marker-arrow:before {
  border-color: #8c689d transparent transparent;
}
.map-marker-container[data-marker_id="3"] .marker-arrow:before {
  border-color: #35c2a5 transparent transparent;
}
.map-marker-container[data-marker_id="4"] .marker-arrow:before {
  border-color: #ff8480 transparent transparent;
}
.map-marker-container[data-marker_id="5"] .marker-arrow:before {
  border-color: #ffb874 transparent transparent;
}
.map-marker-container[data-marker_id="6"] .marker-arrow:before {
  border-color: #ff007a transparent transparent;
}

.map-marker-container[data-marker_id="0"] .face {
  background-color: #4cbfd8;
}
.map-marker-container[data-marker_id="1"] .face {
  background-color: #ff8480;
}
.map-marker-container[data-marker_id="2"] .face {
  background-color: #8c689d;
}
.map-marker-container[data-marker_id="3"] .face {
  background-color: #35c2a5;
}
.map-marker-container[data-marker_id="4"] .face {
  background-color: #ff8480;
}
.map-marker-container[data-marker_id="5"] .face {
  background-color: #ffb874;
}
.map-marker-container[data-marker_id="6"] .face {
  background-color: #ff007a;
}

.marker-card {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  z-index: 1;
}

/*==========================
Map Listing Item
===========================*/

#map .infoBox {
  margin-left: -45px;
  margin-bottom: 15px;
}

.map-listing-item {
  position: relative;
}

.map-listing-item .infoBox-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  background: #f0f5f7;
  color: #202124;
  line-height: 24px;
  font-size: 12px;
  border-radius: 8px;
  z-index: 9;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.map-listing-item .infoBox-close:hover {
  background-color: var(--primary-color);
  color: #ffffff;
}

.map-listing-item .inner-box {
  position: relative;
  padding: 20px 20px;
  padding-left: 90px;
  border-radius: 10px;
  background: #ffffff;
  min-width: 358px;
}

.map-listing-item .inner-box:before {
  position: absolute;
  left: 50%;
  top: 100%;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #ffffff;
  content: "";
}

.map-listing-item .image-box {
  position: absolute;
  left: 20px;
  top: 20px;
}

.map-listing-item .image {
  display: block;
  height: 50px;
  width: 50px;
  overflow: hidden;
  margin-bottom: 0;
}

.map-listing-item .image img {
  display: block;
  max-width: 100%;
  height: auto;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.map-listing-item .content {
  position: relative;
  min-height: 50px;
}

.map-listing-item .title {
  color: #ffffff;
  opacity: 0.6;
  margin-left: 3px;
}

.map-listing-item h3 {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  font-family: Sofia Pro;
  margin-bottom: 4px;
}

.map-listing-item h3 a {
  color: #202124;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.map-listing-item h3 a:hover {
  color: #202124;
}

.map-listing-item .job-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.map-listing-item .job-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  padding-left: 25px;
  margin-right: 20px;
}

.map-listing-item .job-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
}

.map-listing-item .job-info li a {
  color: #696969;
}

/***

==================================================================
Map Section
==================================================================

***/

.map-section {
  position: relative;
}

.map-area {
  position: relative;
}

.map-data {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.map-data a {
  display: block;
}

.map-data h6 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
  color: #121212;
}

.map-canvas {
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;

  iframe {
    width: 100%;
    height: 100%;
  }
}
