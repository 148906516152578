/***

==================================================================
Faqs Section
==================================================================

***/

.faqs-section{
  position: relative;
  padding: 40px 0;
}

.faqs-section h3{
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 12px;
}

.faqs-section .accordion-box{
  margin-bottom: 60px;
}