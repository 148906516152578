/*** 

====================================================================
Section Title
====================================================================

***/

.sec-title-outer{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.sec-title {
  position: relative;
  margin-bottom: 50px;
}

.sec-title .sub-title{
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 10px;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 25px;
  color: var(--primary-color);
  font-weight: 700;
  font-family: Barlow, sans-serif;
}

.sec-title .text {
  position: relative;
  margin-top: 15px;
  font-size: 18px;
  line-height: 25px;

  span{
    display: block;
    text-transform: uppercase;
    color: var(--primary-color);
    font-weight: 700;
  }

  .date{
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin: 15px;
    color: var(--primary-color);
    font-weight: 700;

    p{
      color: var(--primary-color);
    }
  }
}

.sec-title.light h2,
.sec-title.light .text{
  color: rgba(195, 255, 181, 1);
}

.sec-title-outer .link{
  position: relative;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary-color);
  margin-bottom: 50px;
}

.sec-title-outer .link .icon{
  margin-left: 5px;
}

.sec-title-outer select{
  position: relative;
  background: #FFFFFF;
  border: 1px solid #ECEDF2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  min-width: 202px;
  height: 50px;
  line-height: 20px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 19px;
  color: #202124;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sec-title-outer .select-box-outer{
  position: relative;
  margin-bottom: 50px;
}

.sec-title-outer .select-box-outer .icon{
  position: absolute;
  right: 22px;
  top: 50%;
  z-index: 1;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  color: #202124;
  pointer-events: none;
}