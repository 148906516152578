/*** 

====================================================================
Pricing Section
====================================================================

***/

.pricing-section {
  position: relative;
  padding: 100px 0 70px;
}

.pricing-tabs {
  position: relative;
}

.pricing-tabs .tab-buttons {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.pricing-tabs .tab-buttons h4 {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-bottom: 15px;
}

.pricing-tabs .tab-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-tabs .tab-btns:before {
  position: absolute;
  top: 0;
  left: 50%;
  width: 60px;
  height: 30px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--primary-color);
  border: 1px solid #e7e7ec;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 8px 15px rgba(140, 152, 164, 0.1);
  box-shadow: 0px 8px 15px rgba(140, 152, 164, 0.1);
  border-radius: 30px;
  content: "";
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.pricing-tabs .tab-btns li {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  color: #696969;
  cursor: pointer;
  z-index: 8;
  padding: 0 48px 0;
  min-width: 196px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-tabs .tab-btns li:before {
  position: absolute;
  right: -26px;
  height: 20px;
  width: 20px;
  top: 5px;
  background: #ffffff;
  content: "";
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-tabs .tab-btns li.active-btn:before {
  right: 6px;
}

.pricing-tabs .tab-btns li:last-child:before {
  display: none;
}

.pricing-table {
  position: relative;
  margin-bottom: 30px;
}

.pricing-table .inner-box {
  position: relative;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-table .inner-box:hover,
.pricing-table.tagged .inner-box {
  border: 1px solid var(--primary-color);
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.pricing-table .tag {
  position: absolute;
  right: 30px;
  top: 30px;
  overflow: hidden;
  border-radius: 30px;
  font-size: 13px;
  line-height: 20px;
  color: var(--primary-color);
  padding: 5px 25px;
}

.pricing-table .tag:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--primary-color);
  opacity: 0.15;
  content: "";
  border-radius: 30px;
}

.pricing-table .title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.pricing-table .price {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
  margin-bottom: 50px;
  font-weight: 500;
  transition: all 300ms ease;
}

.pricing-table .duration {
  position: relative;
  font-size: 18px;
  line-height: 1em;
  color: #202124;
  font-weight: 400;
  bottom: 7px;
  margin-left: 7px;
}

.pricing-table .table-content {
  position: relative;
  margin-bottom: 60px;
}

.pricing-table .table-content ul {
  position: relative;
}

.pricing-table .table-content ul li {
  position: relative;
  font-size: 14px;
  line-height: 25px;
  color: #696969;
  font-weight: 400;
  margin-bottom: 20px;
  padding-left: 30px;
}

.pricing-table .table-content ul li:before {
  position: absolute;
  left: 0;
  top: 0;
  font-family: Flaticon;
  font-size: 16px;
  color: #202124;
  content: "\f172";
}

.pricing-table .table-footer {
  position: relative;
}

.pricing-table .table-footer a {
  position: relative;
  display: block;
  width: 100%;
}
