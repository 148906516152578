/*======================
Checkbox 
=======================*/
.checkbox-outer {
  position: relative;
  margin-bottom: 30px;
}
.checkboxes {
  list-style: none;
  padding: 0;
  li {
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
.checkboxes {
  &.inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.checkboxes {
  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    color: #696969;
    span {
      color: #909090;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      position: absolute;
      left: 0;
      top: 2px;
      background-color: #fff;
      border: 1px solid #ecedf2;
      border-radius: 10px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      font-family: "Font Awesome 5 Free", sans-serif;
      color: #fff;
      font-size: 9px;
      font-weight: 900;
      text-align: center;
      line-height: 17px;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="radio"] {
    display: none;
  }
}

.checkboxes {
  input[type="checkbox"] {
    &:checked {
      + {
        label {
          &:before {
            content: "\f00c";
            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
          }
        }
      }
    }
  }
  input[type="radio"] {
    &:checked {
      + {
        label {
          &:before {
            content: "\f00c";
            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
          }
        }
      }
    }
  }
}

.checkboxes.square {
  label {
    &:before {
      border-radius: 2px;
    }
  }
}
.checkboxes.two-column {
  &:after {
    display: table;
    clear: both;
    content: "";
  }
  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    float: left;
    margin: 0;
    min-width: 50%;
    padding-right: 10px;
  }
}
.checkbox-outer {
  .view-more {
    position: relative;
    font-size: 13px;
    line-height: 20px;
    color: var(--primary-color);
    font-weight: 400;
    display: flex;
    align-items: center;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      font-weight: 900;
      font-size: 10px;
      border-radius: 50%;
      margin-right: 10px;
      background: rgba(25, 103, 210, 0.15);
      border-radius: 60px;
    }
  }
}

/*======================
Radio bOX
=======================*/
.radio-box {
  position: relative;
  label {
    position: relative;
    display: block;
    font-size: 15px;
    color: #696969;
    line-height: 20px;
    padding-left: 30px;
    cursor: pointer;
    margin-bottom: 18px;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 16px;
      width: 16px;
      background: #ffffff;
      content: "";
      border: 1px solid #ecedf2;
      border-radius: 50px;
      background: #ffffff;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;
    }
    &:after {
      position: absolute;
      content: "";
      left: 4px;
      top: 4px;
      height: 8px;
      width: 8px;
      border-radius: 5px;
      overflow: hidden;
      background: transparent;
      text-align: center;
    }
  }
  input[type="radio"] {
    display: none;
    &:checked {
      + {
        label {
          border-color: var(--primary-color);
        }
      }
    }
  }
}

.radio-box {
  input[type="radio"] {
    &:checked {
      + {
        label {
          &:before {
            border-color: var(--primary-color);
          }
          &:after {
            background-color: var(--primary-color);
            opacity: 1;
          }
        }
      }
    }
  }
}

/*======================
Switches 
=======================*/

.switchbox-outer {
  position: relative;
}

.switchbox-outer h4 {
  font-size: 16px;
  line-height: 1em;
  color: #1b2032;
  font-weight: 700;
  margin-bottom: 15px;
}

.switchbox {
  position: relative;
}

.switchbox li {
  position: relative;
  margin-bottom: 20px;
}

.switchbox .title {
  position: relative;
  margin-left: 10px;
  font-size: 15px;
  color: #5c6770;
  line-height: 20px;
}

.switch {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  background-color: #d2d3d8;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

input:checked + .slider:before {
  background: #ffffff;
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/*=== Default Form ===*/
.switchbox-outer {
  position: relative;
  h4 {
    font-size: 16px;
    line-height: 1em;
    color: #1b2032;
    font-weight: 700;
    margin-bottom: 15px;
  }
}
.switchbox {
  position: relative;
  li {
    position: relative;
    margin-bottom: 20px;
  }
  .title {
    position: relative;
    margin-left: 10px;
    font-size: 15px;
    color: #5c6770;
    line-height: 20px;
  }
}
.switch {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider {
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 3px;
    background-color: #d2d3d8;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
}
input {
  &:checked {
    + {
      .slider {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);
        &:before {
          background: #ffffff;
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(19px);
          transform: translateX(19px);
        }
      }
    }
  }
}
.default-form {
  position: relative;
  .form-group {
    position: relative;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    > label {
      font-family: Barlow, sans-serif;
      font-size: 15px;
      line-height: 20px;
      color: #202124;
      font-weight: 500;
      margin-bottom: 10px;
    }
    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
      position: relative;
      width: 100%;
      display: block;
      height: 60px;
      line-height: 30px;
      padding: 15px 20px;
      font-size: 15px;
      color: #696969;
      background-color: #f0f5f7;
      border: 1px solid #f0f5f7;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 8px;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;
      &:focus {
        border-color: var(--primary-color);
        background-color: #ffffff;
      }
    }

    textarea {
      position: relative;
      width: 100%;
      display: block;
      height: 60px;
      line-height: 30px;
      padding: 15px 20px;
      font-size: 15px;
      color: #696969;
      background-color: #f0f5f7;
      border: 1px solid #f0f5f7;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 8px;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;
      height: 160px;
      resize: none;
      padding-top: 15px;
      &:focus {
        border-color: var(--primary-color);
        background-color: #ffffff;
      }
    }
    select {
      position: relative;
      width: 100%;
      display: block;
      height: 60px;
      line-height: 30px;
      padding: 15px 20px;
      font-size: 15px;
      color: #696969;
      background-color: #f0f5f7;
      border: 1px solid #f0f5f7;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 8px;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;
      &:focus {
        border-color: var(--primary-color);
        background-color: #ffffff;
      }
    }
    input[type="submit"] {
      position: relative;
      display: inline-block;
      min-width: 160px;
    }
    > button {
      position: relative;
      display: inline-block;
      min-width: 160px;
    }
    > .icon {
      position: absolute;
      right: 20px;
      bottom: 0;
      color: #9fa9b8;
      font-size: 14px;
      line-height: 54px;
    }
    .rating {
      position: relative;
      a {
        position: relative;
        font-size: 13px;
        color: #999999;
        &:hover {
          color: #ffc78b;
        }
      }
    }
  }
  input.error {
    &:focus {
      border-color: #ff0000;
    }
  }
  select.error {
    &:focus {
      border-color: #ff0000;
    }
  }
  textarea.error {
    &:focus {
      border-color: #ff0000;
    }
  }
  label.error {
    display: block;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    line-height: 24px;
    color: #ff0000;
    margin-bottom: 0;
  }
}
.default-form.job-apply-form {
  .form-group {
    margin-bottom: 30px;
    a {
      color: #333;
      &:hover {
        color: #1451a4;
      }
    }
  }
}
#email-form {
  .form-group {
    &:first-child {
      margin-bottom: 0;
    }
  }
  .response {
    position: relative;
    text-align: center;
    .success {
      background: #35c2a5;
      color: #ffffff;
      padding: 0 10px;
      margin-bottom: 10px;
    }
    .failed {
      background-color: #f74848;
      color: #ffffff;
      padding: 0 10px;
      margin-bottom: 10px;
    }
    .text-info {
      background-color: #ffa737;
      color: #222222 !important;
      padding: 0 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.chosen-container-multi {
  .chosen-choices {
    li.search-field {
      input[type="text"] {
        line-height: 60px !important;
        min-height: 60px !important;
      }
    }
    background: #f0f5f7;
    min-height: 60px;
    line-height: 60px;
    border: 0 !important;
    border-radius: 8px;
    li {
      &.search-choice {
        color: rgba(25, 103, 210, 1);
        font-size: 13px;
        line-height: 25px;
        color: var(--primary-color);
        padding: 0 25px 0 10px;
        border-radius: 10px;
        background: rgba(25, 103, 210, 0.1);
        margin-top: 15px;
        .search-choice-close {
          &:before {
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            top: 3px;
            color: rgba(25, 103, 210, 1);
          }
        }
      }
    }
  }
}
.chosen-container-active {
  .chosen-choices {
    li.search-field {
      input[type="text"] {
        line-height: 60px !important;
        min-height: 60px !important;
      }
    }
  }
}
.chosen-container.chosen-with-drop {
  .chosen-drop {
    background: #f0f5f7;
    min-height: 60px;
    line-height: 60px;
    border: 0 !important;
    border-radius: 8px;
  }
}
.chosen-container-single {
  .chosen-single {
    background: #f0f5f7;
    border: 0 !important;
    border-radius: 8px;
  }
  .chosen-search {
    &:before {
      display: none;
    }
    input[type="text"] {
      height: 40px;
      line-height: 20px;
      padding: 10px 20px;
    }
  }
}
.form-select {
  &:focus {
    border-color: #ecedf2;
    outline: 0;
    box-shadow: none;
  }
}

// mulit selectbox
.default-form {
  .form-group {
    input.select__input {
      height: auto;
    }
  }
}

.default-form {
  .form-group {
    .css-13cymwt-control {
      border-color: #f0f5f7;
      background-color: #f0f5f7;
      min-height: 60px;
      border-radius: 6px;
    }
    .select__control--is-focused {
      border-color: #f0f5f7;
      background-color: #f0f5f7;
      min-height: 60px;
      border-radius: 6px;
    }
  }
}
.select__multi-value {
  &.css-1p3m7a8-multiValue {
    background-color: #fff;
  }
}

.register-dual {
  ul {
    li.react-tabs__tab--selected {
      .btn-style-four {
        background: #34a853;
        color: #fff;
      }
    }
  }
}
