/***

====================================================================
News Section
====================================================================

***/

.news-section {
    position: relative;
    background-color: #ecedf2;
    padding: 100px 0 70px;
}

.news-block {
    position: relative;
}

.news-block-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.news-block .inner-box {
    position: relative;
    overflow: hidden;
    padding: 10px;
    border-radius: 10px;
    background: #ffffff;
}

.news-block .image-box {
    position: relative;
}

.news-block {
    .image-box {
        .image {
            position: relative;
            border-radius: 25px;
            overflow: hidden;
            margin-bottom: 0;
            height: 198px;
            width: 300px;
            background: #F7FEF6;

            &.col4 {
                width: auto;
            }

            /*&::before,
            &::after {
                content: "";
                position: absolute;
                border-radius: 50%;
                opacity: 0.3;
            }

            &::before {
                width: 200px;
                height: 200px;
                background-color: var(--primary-color);
                top: -100px;
                right: -114px;
            }

            &::after {
                width: 200px;
                height: 200px;
                background-color: var(--primary-3rd-color);
                top: 68px;
                right: -128px;
            }*/
        }
    }
}
.items4 .news-block {
    .image-box {
        .image {
            height: 153px;
        }
    }
}
.news-block .image-box img {
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    transition: all 300ms ease;
}

.news-block .image-box:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.news-block .inner-box:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.news-block .lower-content {
    padding: 20px 0;
    position: relative;
    width: 100%;
}

.news-block .post-meta {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.news-block .post-meta li {
    position: relative;
    font-size: 14px;
    line-height: 24px;
    color: #696969;
    margin-right: 15px;
    padding-left: 20px;
    margin-bottom: 5px;
}

.news-block .post-meta li:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -3px;
    height: 6px;
    width: 6px;
    background: #696969;
    border-radius: 5px;
    content: "";
}

.news-block .post-meta li:first-child {
    margin-left: 0;
    padding-left: 0;
}

.news-block .post-meta li:first-child:before {
    display: none;
}

.news-block .post-meta li a:hover,
.news-block .post-meta li a {
    color: inherit;
}

.news-block .lower-content a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 40px;
}
.news-block .lower-content p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.news-block .lower-content h3 {
    font-size: 14px;
    line-height: 20px;
    color: #202124;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    min-height: 40px;
}
.news-block .lower-content h3.titleJeu{
    text-align: center;
    min-height: 15px;
    margin-bottom: 5px;
}
.news-block .lower-content h3 a {
    color: #202124;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.news-block .lower-content h3.titleJeu a{
    color: var(--primary-color);
}
.theme-btn.small.btn-padding-20{
    padding-left: 35px;
    padding-right: 35px;
}
.news-block .lower-content .dateJeu{
    font-size: 13px;
}

.news-block .lower-content h3 a:hover {
    color: var(--primary-color);
}

.news-block .lower-content .text {
    font-size: 14px;
    line-height: 24px;
    color: #696969;
    margin-bottom: 15px;
}

.news-block .read-more {
    position: relative;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: var(--primary-color);
}

.news-block .read-more i {
    margin-right: 10px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.news-block .read-more:hover {
    color: inherit;
    color: var(--primary-color);
}

.news-block .read-more:hover::before {
    content: '';
    display: block;
    width: 85%;
    border-bottom: 4px solid var(--primary-color);
    position: absolute;
    bottom: -6px;
    right: 0;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.news-block .read-more:hover i {
    opacity: 0;
    margin-left: 20px;
}

/* Style Two */

.news-section.style-two {
    background: #f3f7fb;
}

.news-section.style-two .row {
    margin: 0 -26px;
}

.news-section.style-two .news-block {
    padding: 0 26px;
}

.news-section.style-two .news-block .inner-box {
    padding: 0;
    background-color: transparent;
}

.news-section.style-two .news-block .lower-content {
    padding: 17px 0 0;
}

/* Style Three */

.news-section.style-three .news-block .post-meta {
    margin-bottom: 10px;
}

.news-section.style-three .news-block .inner-box {
    -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
    border-radius: 8px;
}

.news-section.style-three .news-block .lower-content h3 {
    margin-bottom: 0;
}

.news-section.style-three .grid .news-block .lower-content {
    padding-bottom: 10px;
    padding-top: 19px;
}

/***

====================================================================
News Section Two
====================================================================

***/

.news-section-two {
    position: relative;
    padding: 100px 0 50px;
}

.news-section-two .sec-title {
    margin-bottom: 50px;
}

.news-section-two .news-block {
    margin-bottom: 50px;
}

.news-section-two .news-block .inner-box {
    padding: 0;
    background-color: transparent;
}

.news-section-two .news-block .lower-content {
    padding: 17px 0 0;
}

.news-section-two .news-block .post-meta {
    margin-bottom: 0;
}

.news-section-two .news-block h3 {
    margin-bottom: 0;
}

/* Style Two */
.news-section-two.style-two {
    background: #f5f7fc;
}

/***

====================================================================
News Section Three
====================================================================

***/

.news-section-three {
    position: relative;
    background-color: #ecedf2;
    padding: 100px 0 70px;
}

.news-block-two {
    position: relative;
    margin-bottom: 30px;
}

.news-block-two .inner-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ecedf2;
    background: #ffffff;
}

.news-block-two .image-box {
    position: relative;
    flex: 0 0 45%;
}

.news-block-two .image-box .image {
    position: relative;
    border-radius: 10px;
    min-width: 200px;
    overflow: hidden;
    margin-bottom: 0;
}

.news-block-two .image-box img {
    display: block;
    width: 100%;
    min-height: 258px;
    object-fit: cover;
    transition: all 300ms ease;
}

.news-block-two .inner-box:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.news-block-two .content-box {
    padding: 15px 30px;
    position: relative;
    flex: 1;
}

.news-block-two .post-meta {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.news-block-two .post-meta li {
    position: relative;
    font-size: 14px;
    line-height: 24px;
    color: #696969;
    margin-right: 15px;
    padding-left: 20px;
    margin-bottom: 5px;
}

.news-block-two .post-meta li:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -3px;
    height: 6px;
    width: 6px;
    background: #696969;
    border-radius: 5px;
    content: "";
}

.news-block-two .post-meta li:first-child {
    margin-left: 0;
    padding-left: 0;
}

.news-block-two .post-meta li:first-child:before {
    display: none;
}

.news-block-two .post-meta li a:hover,
.news-block-two .post-meta li a {
    color: inherit;
}

.news-block-two .content-box h3 {
    font-size: 18px;
    color: #202124;
    font-weight: 500;
    margin-bottom: 10px;
}

.news-block-two .content-box h3 a:hover,
.news-block-two .content-box h3 a {
    color: #1b2032;
}

.news-block-two .content-box .text {
    font-size: 14px;
    line-height: 24px;
    color: #696969;
    margin-bottom: 15px;
}

.news-block-two .read-more {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: var(--primary-color);
}

.news-block-two .read-more i {
    margin-left: 10px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.news-block-two .read-more:hover {
    color: inherit;
    color: var(--primary-color);
}

.news-block-two .read-more:hover i {
    opacity: 0;
    margin-left: 20px;
}

// some hight changes css for large image
.news-section.style-three {
    .image-large {
        img {
            min-height: 468px;
            object-fit: cover;
            @media (max-width: 767px) {
                min-height: 200px;
            }
        }
    }
    .image-regular {
        img {
            min-height: 163px;
            object-fit: cover;
            @media (max-width: 1024px) {
                min-height: 200px;
            }
        }
    }
}

.news-section.style_2 {
    .news-block-two {
        .inner-box {
            @media (max-width: 991px) {
                flex-direction: column;
            }
        }
        .image-box {
            flex: 0 0 200px;
            height: 200px;
            .image {
                height: 100%;
            }
            img {
                min-height: 100%;
                height: 100%;
            }
        }
    }
}
