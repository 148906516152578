.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader__logo {
  width: 15%;
  height: 15%;
  background-image: url('../images/Logo-THF-couleurs.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 2;
}

.loader__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader__background--white {
  background-color: #e2eaf8;
  z-index: 1;
}

.loader__background--green {
  background-color: var(--primary-3rd-color);
}

.loader__background--blue {
  background-color: var(--primary-hover-bg-color);
}